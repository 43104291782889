import React, { useState, useEffect, useCallback, useRef } from "react"

import VehicleRecommendation from "../components/recommendation/VehicleRecommendation"
import VDPModalContainer from "../components/vdp/VDPModalContainer"

import { ModalVehicleProvider } from "../components/modal/storage/VehicleContext"

export default function SearchRecommended({ props }) {
  const searchdata = typeof props !== 'undefined' ? props : {
    siteSettings: { theme: { colors: {} } },
    StateResults: [],
    allDataDict: [],
    setFavoriteItems: () => { },
    favoriteItem: () => { },
    msrpClass: () => { },
    formatPrice: () => { },
    sortTheItems: () => { },
    useCompactCards: false,
    additionalInventory: false
  }

  const { rates, downpayment, defaultMonth } = searchdata
  const { disclaimerCalculator, disclaimerContact, disclaimerFinanceApp, HideNewUsed } = searchdata
  const { lead_url, site_id, gtmId } = searchdata
  const { filterState, overwriteCTAs, overwriteInlineOffers, staticBtns, staticSrpInlineOffers} = searchdata
  const { additionalInventory } = searchdata

  const localStorage = typeof window !== 'undefined' && window.localStorage
  let colorTheme = searchdata.siteSettings.theme.colors

  //These fields commented out until inline offers is implemented
  //let offers = overwriteInlineOffers? staticSrpInlineOffers.inlineOffers : searchdata.siteSettings.inlineOffers.slideList
  //let freq = overwriteInlineOffers? staticSrpInlineOffers.freq : searchdata.siteSettings.inlineOffers.freq

  const { StateResults } = searchdata
  const { theSlug, formatPrice, allDataDict, sortTheItems } = searchdata
  const { SRPCardBtns } = searchdata
  const [hasMounted, setHasMounted] = React.useState(false);
  const [page, setPage] = useState(1)
  const [StateFavorites, setFavorites] = useState(getFavorites());

  function getFavorites() {
    let favs = getStorageItem("favoriteItem")
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    return theFavs
  }

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
  }

  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
      ? localStorage.setItem(item, value)
      : console.log('no localStorage')
  }

  function isFavorite(vin) {
    return StateFavorites.findIndex((v) => (v == vin)) !== -1
  }

  function favoriteItem(vin) {
    let favs = getStorageItem("favoriteItem")
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    theFavs = theFavs.filter((value, index, self) => self.indexOf(value) === index)
    if (!theFavs.includes(vin)) {          //checking weather array contain the id
      theFavs.push(vin)
    } else {
      theFavs.splice(theFavs.indexOf(vin), 1);  //deleting
    }
    setStorageItem("favoriteItem", JSON.stringify(theFavs));
    setFavorites(theFavs);
    props.setFavorites(theFavs)
  }

  const observer = useRef()
  const lastItem = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage(page + 1)
      }
    });
    if (node)
      observer.current.observe(node);
  }
  )

  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <>
      <ModalVehicleProvider>
        <VDPModalContainer
          siteData={{
            lead_url, site_id, gtmId,
            disclaimerContact, disclaimerFinanceApp, disclaimerCalculator
          }}
          financeData={{ rates, downpayment, defaultMonth }}
        />
        <section className="sr-container">
          <ul className="sr">
            {sortTheItems(StateResults).slice(0, Math.min(6 * page, StateResults.length)).map((result, index) => {
              let item = allDataDict[result.ref]
              let cnt = (6 * page) - 3
              return (
                <li ref={index == cnt ? lastItem : {}} className={"sr-item sr-item_page_" + Math.ceil((index + 1) / 10)}>
                  <VehicleRecommendation 
                    showTrim={props.showTrim}
                    showSpecs={props.showSpecs}
                    fallbackText={props.fallbackText}
                    isFavorite={isFavorite(item.VehicleInfo.VIN)}
                    favorite={favoriteItem}
                    theSlug={theSlug}
                    item={item}
                    unavailableImg={props.unavailableImg}
                    HideNewUsed={HideNewUsed}
                    SRPCardBtns={SRPCardBtns}
                    formatPrice={formatPrice}
                    AutoCheckEnabled={props.AutoCheckEnabled}
                    overwriteCTAs={overwriteCTAs}
                    staticBtns={staticBtns}
                    additionalInventory={additionalInventory}
                    netPriceCustomText={props.netPriceCustomText}
                  />
                </li>
              )
            })}
          </ul>
        </section>
      </ModalVehicleProvider>
    </>
  )
}
